import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const TwitterCampaignRulesTemplateWhatToIncludeToPreventTrouble = ({
    location
  }) => {
  const title =
    "【テンプレ公開】Twitterキャンペーンの応募規約｜トラブルを防ぐために記載すべきこと"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【テンプレ公開】Twitterキャンペーンの応募規約｜トラブルを防ぐために記載すべきこと"
          pagedesc="各企業が自社の製品やサービスをプロモーションする手法のひとつであるTwitterキャンペーン。トラブル防止するためにも、応募規約をしっかりと定めることが重要です。本記事では、応募規約を作る際のポイントや応募規約のテンプレをご紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230727/230727-1.png"
          pagepath="/blog/twitter-campaign-rules-template-what-to-include-to-prevent-trouble"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              【テンプレ公開】Twitterキャンペーンの応募規約｜トラブルを防ぐために記載すべきこと
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年7月27日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="スマートフォンを操作している人"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230727/230727-1.png"
                className="w-full"
              />
            </figure>
            <h2 id="id1">多くの企業で実施しているTwitterキャンペーン！しかし…</h2>
            <p>
              自社の製品やサービスをプロモーションするのに、多くの企業が実施しているTwitterキャンペーン。拡散力が高く、多くの参加者を集めるのに効果的ですが、その分トラブルに注意しなければなりません。ルールを明確に示しておかないと、ユーザーがルールに違反した際、場合によってはトラブルに発展してしまう恐れがあります。
            </p>

            <h2 id="id2">安全にTwitterキャンペーンを実施するために</h2>
            <p>トラブルを防ぐためにも、Twitterキャンペーンを行う際は、応募規約をしっかりと定め、ユーザーに示すことが重要です。</p>
            <p>そこで、本記事ではTwitterキャンペーンにおける応募規約について詳しく解説します。</p>
            <p>
              応募規約の内容や制定する際のポイントのほか、PARKLoTが実際に使用しているTwitterインスタントウィン用のテンプレも公開します。応募規約をどのように記載すればいいのかわからず困っているTwitterキャンペーンの担当者や関係者の方は、ぜひ参考にしてみてください。
            </p>

            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>Twitterキャンペーンの応募規約について詳しく知りたい方</li>
              <li>応募規約の作り方に悩んでいる方</li>
              <li>応募規約のテンプレを探している方</li>
            </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    多くの企業で実施しているTwitterキャンペーン！しかし…
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    安全にTwitterキャンペーンを実施するために
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. Twitterキャンペーンにおける応募規約とは？規約の内容や掲載場所は？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        Twitterキャンペーンの応募規約とは？
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        応募規約を定めておくべき理由
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        応募規約の主な内容
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        応募規約の掲載場所は？
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    2. 応募規約を作成する際に必ず確認しておきたいポイント
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        Twitterのガイドラインの内容を含める
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        法的に問題ないかをチェック
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    3. PARKLoTのTwitterキャンペーン用テンプレを公開！
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ▶︎ 参加方法
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        ▶︎ 応募資格
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ▶︎ 注意事項
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        ▶︎ 個人情報の取り扱い
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        ▶︎ 本キャンペーン主催者
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id17" className="toc__link">
                    4. 主催者側とユーザー側の双方を守るためにも応募規約はしっかりと作ることが大切
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id18" className="toc__link">
                        応募規約を作成してトラブルを防止しよう
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id19" className="toc__link">
                        応募規約を作成する工数を減らしたいなら、PARKLoTへ
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. Twitterキャンペーンにおける応募規約とは？規約の内容や掲載場所は？</h2>

              <h3 id="id4">Twitterキャンペーンの応募規約とは？</h3>
              <p>
                Twitterキャンペーン上の応募規約は、ユーザーがキャンペーンに参加する際のルールを明記したもの。主催者とユーザーの双方が、ルールを正確に把握するのに重要な役割を果たします。
              </p>
              <p>
                応募規約には、企業で定めたキャンペーンを円滑に進めるためのルールのほか、Twitter社が定めるガイドラインの内容なども記載するのが一般的です。
              </p>

              <h3 id="id5">応募規約を定めておくべき理由</h3>
              <p>
                なぜ応募規約を定める必要があるのか？それは、多重応募や多重当選などのトラブルを防ぐためです。トラブルが起きた場合、応募規約にはっきりとルールが書かれていれば、責任の所在を明確にすることができます。
              </p>
              <p>
                特に、「応募＝規約に同意」といった旨の記載をしておけば、お互いに規約内容を理解しているという認識で話し合いを進められるため、問題を解決しやすくなります。
              </p>
              <p>
                トラブルを未然に防いだり、問題発生後もスムーズに解決したりできるよう、応募規約はしっかりと定めておきましょう。
              </p>

              <h3 id="id6">応募規約の主な内容</h3>
              <p>
                応募規約の内容は、基本的には自由ですが、いくつか代表的な項目を紹介したいと思います。応募規約の前にキャンペーンの概要も載せておくと、ユーザーがキャンペーン全体を理解しやすくなります。
              </p>
              <ol>
                <li><b>キャンペーン概要</b>
                  <ul>
                    <li>期間</li>
                    <li>景品</li>
                    <li>当選人数</li>
                    <li>応募方法</li>
                    <li>抽選・結果発表の方法</li>
                  </ul>
                </li>
                <li><b>応募規約</b>
                  <ul>
                    <li>参加方法</li>
                    <li>応募資格</li>
                    <li>注意事項</li>
                    <li>個人情報の取り扱い</li>
                  </ul>
                  <p>（各項目の詳細は「
                  <a href="#id11">
                    3. PARKLoTのTwitterキャンペーン用テンプレを公開！
                  </a>」を参照してください。）</p>
                </li>
              </ol>

              <h3 id="id7">応募規約の掲載場所は？</h3>
              <p>
                応募規約を定めたら、ユーザーの目に留まりやすい場所に掲載しましょう。Twitterキャンペーンの場合、以下の2か所のいずれかに掲載するのが一般的です。
              </p>
              <ol>
                <li>
                  <b>Webページ</b>
                  <p>
                    キャンペーンのWebページ上に応募規約を掲載する方法です。または、自社サイトに応募規約のみを掲載し、URLで誘導する方法もあります。
                  </p>
                  <p>
                    Webページが元々ある場合は応募規約をそのまま掲載するだけですが、Webページがない場合はページ作成に少々手間がかかります。自社で作成するのが難しい場合は、外注して作成することもできます。
                  </p>
                </li>
                <li>
                  <b>キャンペーンツイート</b>
                  <p>
                    Twitterのキャンペーン投稿に対してリプライを行い、リプライに応募規約を載せる方法もあります。手軽に応募規約を公開できるほか、ユーザーが別のページにわざわざ遷移する必要がないのがメリットです。
                  </p>
                  <p>
                    なお、応募規約の画像を入れる場合は、ALT属性に文字情報を入れるようにしましょう。画像が表示できない場合にテキストで内容を確認できるほか、読み上げ機能を使って音声で確認できるようになります。
                  </p>
                </li>
              </ol>

            </div>
            <div>
              <h2 id="id8">2. 応募規約を作成する際に必ず確認しておきたいポイント</h2>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="応募規約を作成する際に必ず確認しておきたいポイント"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230727/230727-2.png"
              />
              <p>
                応募規約を作る際、キャンペーンのルール以外にも重要なポイントがあります。
              </p>

              <h3 id="id9">Twitterのガイドラインの内容を含める</h3>
              <p>
                Twitter社は、「
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules"
                  target="_blank"
                  rel="noreferrer"
                >
                  キャンペーンの実施についてのガイドライン
                </a>」（以下、「ガイドライン」）を定めています。そのため、Twitterを利用する時は、前提としてガイドラインの内容を守らなくてはいけません。
              </p>
              <p>
                キャンペーンに応募するユーザーで、わざわざガイドラインを確認する人はほとんどいないでしょう。そのため、応募者が知らずにガイドラインに違反してしまうことがないよう、応募規約にガイドラインの内容を含めることが重要です。
              </p>
              <p>
                <b>応募規約に掲載すべきガイドラインの内容の一例</b>
              </p>
              <p>ガイドラインには、以下のような内容が書かれています。</p>
              <p>（例）</p>
              <ul>
                <li>キャンペーン用に複数のアカウント作成の禁止</li>
                <li>同じ内容のツイート（複数回の応募）の禁止</li>
                <li>応募ツイートに入れる＠ユーザー名（主催者名など）を指定する</li>
                <li>ハッシュタグ投稿をする場合は、ハッシュタグに関係ある内容のツイートでなければならない</li>
              </ul>
              <p>
                これらの内容を応募規約に含めることで、ユーザーが意図せずガイドラインに違反することを防ぐことができます。なお、ガイドラインは告知なく変更されている可能性もあるので、応募規約を作成する際は必ず最新の情報を確認するようにしてください。
              </p>
              <p><b>▼キャンペーンの実施についてのガイドライン</b></p>
              <a
                href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules"
                target="_blank"
                rel="noreferrer"
              >
                https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules
              </a>

              <h3 id="id10">法的に問題ないかをチェック</h3>
              <p>
                キャンペーンによっては、「リツイート」や「いいね」以外に、ユーザーが写真や動画をアップロードして応募する場合もあります。その場合、アップロードするモノが、著作権や肖像権などの法令に抵触しないよう、注意喚起をしておく必要があります。大きなトラブルにつながる恐れがあるので、しっかり明記しておきましょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/how-to-create-twitter-conversation-button-and-conversation-card-for-free"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【最新】Twitterカンバセーションボタンを無料で作る方法｜ハッシュタグ用カンバカードとは？
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id11">3. PARKLoTのTwitterキャンペーン用テンプレを公開！</h2>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PARKLoTのTwitterキャンペーン用テンプレ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230727/230727-3.png"
              />
              <p>
                応募規約について、理解できたけれども、実際に一から作るのは難しいと感じている方も多いと思います。PARKLoTで実際に使用しているTwitterインスタントウィン用の応募規約のテンプレを公開するので、応募規約作成時の参考にしてみてください。
              </p>

              <h3 id="id12">▶︎ 参加方法</h3>
              <div className="blog__border-highlight">
                <ol>
                  <li>Twitterで株式会社○○アカウント（＠×××）をフォロー</li>
                  <li>本キャンペーン対象ツイートをリツイート</li>
                  <li>当選者にのみDMが届く！</li>
                </ol>
              </div>

              <h3 id="id13">▶︎ 応募資格</h3>
              <div className="blog__border-highlight">
                <p>
                  キャンペーンへの応募には、Twitterへの登録（無料）が必要です。<br />
                  株式会社○○ アカウント（＠×××）をフォローし、本キャンペーン対象の投稿をリツイートすることで応募の意思確認とさせていただきます。<br />
                  本キャンペーン実施期間中はTwitterのIDを変更しないようお願いいたします。<br />
                  Twitterアカウントの設定や、参加者の通信状況等に起因して応募が完了できなかった場合であっても、仕様上参加とみなされません。<br />
                  複数のTwitterアカウントで応募された場合、当たり資格を無効といたします。（※1）<br />
                  弊社関係者及び当キャンペーン関係者の応募はできません。<br />
                  応募は日本国内にお住まいの方に限らせていただきます。（※2）<br />
                  DMを受け取れない設定になっている場合、当選は無効となります。ご応募前にアカウントの設定をご確認ください。アカウントの設定でDMが届かない場合でも再送などの対応は行いません。（※3）<br />
                  未成年の方は、親権者の方が応募規約に同意頂いた上でキャンペーンの応募をお願いいたします。
                </p>
              </div>
              <p>※1 ガイドラインの内容</p>
              <p>※2 商品発送をするキャンペーンで、海外発送を想定していない場合は入れておきましょう</p>
              <p>※3 DMが受け取れない設定になっていると、DMが送れずユーザーを連絡がとれないため、あらかじめ設定が受け取れない設定の場合は無効になる旨を明確にしておくのがおすすめです。</p>

              <h3 id="id14">▶︎ 注意事項</h3>
              <div className="blog__border-highlight">
                <p>
                  本キャンペーンについて、Twitter上のDMおよびリプライでご連絡いただきましても、返信はいたしません。こちらのメールアドレスまでご連絡ください。<br />
                  厳正なる抽選の上、当たりのお客様は応募の際にご使用いただいたTwitterのアカウントで○○へログインし、マイページでご確認いただけます。その際に応募方法に応じて以下の点にご注意ください<br />
                  あたりの場合、フォローいただいたアカウント（＠×××）からお客様のアカウントへDMによる通知にてギフトコードをお送りします。<br />
                  Twitterのクオリティーフィルターをオンにしている場合、DMによる通知を受け取れない場合がございます。<br />
                  当たりが無効となった場合、その後、賞品の送付依頼をいただいたとしても一切受け付けかねます。なお、無効となった当たりが発生した場合、当たりの人数は上記記載の数に達しないことがあります。<br />
                  賞品は予告なく変更となる場合がございますので、あらかじめご了承ください。<br />
                  当たりの権利は当たりのお客様本人のものとなり、ご家族・ご友人等への譲渡、転売、換金はできません。<br />
                  賞品のネットオークション等での転売は、禁止いたします。<br />
                  主催者の意図に反して、システムのバグや、意図的にシステムのバグやトラブルを利用して当たられた場合、当たりの権利が無効となります。<br />
                  本キャンペーンは、予告なく中止または内容が変更となる場合がございますので、あらかじめご了承ください。<br />
                  キャンペーンの応募状況および抽選結果に関するお問い合わせにはお答えしかねますので、あらかじめご了承ください。<br />
                  本キャンペーンはTwitter社の提供・協賛によるものではありません。<br />
                  本キャンペーンは▲▲社の提供・協賛によるものではありません。<br />
                  投稿者は、Twitter社の定める利用規約を遵守するものとし、自己の責任において本キャンペーンへ参加するものとします。（※4）<br />
                  本キャンペーンへの投稿に起因するアカウントの制限または凍結に関して当社及び▲▲社は一切の責任を負いかねますので、あらかじめご了承ください。（※5）<br />
                  本キャンペーンへの応募のためのツイート内容(テキスト及び画像)は第三者の権利を侵害するもの、ポルノ、わいせつ、俗悪、冒とく、憎悪、偏見、人種差別、ものまね、不当な暴力を誘発、促進、または表現している内容は一切含まれていないものに限ります。<br />
                  第三者になんらかの損害を与えた場合は、参加者は自らの責任において対応し且つ参加者がこれを補償するものとします。（※6）<br />
                  また、ツイート内容や投稿の掲載により、第三者との間で紛争が生じた場合は、参加者は参加者の責任と費用により当該紛争を解決するものとします。（※6）<br />
                  インターネット通信料・接続料はお客様の負担となります。<br />
                  通信の際の接続トラブルにつきましては、責任を負いかねますので、ご了承ください。<br />
                  その他、本キャンペーンに関するトラブルに関して▲▲社は一切の責任を負いません。
                </p>
              </div>
              <p>※「▲▲社」は業務代行している企業名を想定しています。</p>
              <p>※4 この内容を入れることで、参加者がTwitter者の利用規約に同意したうえで参加していることを明確化できます。</p>
              <p>※5 場合によっては、ユーザーのアカウントへの影響があることを示しています。</p>
              <p>※6 法令に抵触した場合の責任の所在を明確にしています。</p>

              <h3 id="id15">▶︎ 個人情報の取り扱い</h3>
              <div className="blog__border-highlight">
                <p>お客様の個人情報の取り扱いについて、個人情報の保護に関する法令等を順守するとともに、主催者のプライバシーポリシーと▲▲のプライバシーポリシーに準じ、適切な管理を実施してまいります。</p>
              </div>

              <h3 id="id16">▶︎ 本キャンペーン主催者</h3>
              <div className="blog__border-highlight">
                <p>株式会社○○ （＠×××）</p>
              </div>
              <br />
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/ten-things-to-check-for-Increasing-twitter-followers-through-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワー数が伸びない時こそ確認したい10選【プレゼント企画を成功させるには？】
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id17">４.主催者側とユーザー側の双方を守るためにも応募規約はしっかりと作ることが大切</h2>
              <h3 id="id18">応募規約を作成してトラブルを防止しよう</h3>
              <p>
                Twitterキャンペーンは、主催者側・ユーザー側のどちらにとってもメリットの多い企画です。トラブルを防止して、スムーズにキャンペーンを実施するためにも、あらかじめ応募規約を作成しておくことが非常に重要です。
              </p>
              <p>
                作成するときは、企業のルールだけでなく、最新のガイドラインや法令も合わせて確認し、規約に含めることもポイント。応募規約が完成したら、ユーザーが確認しやすい場所に掲載するようにしましょう。
              </p>

              <h3 id="id19">応募規約を作成する工数を減らしたいなら、PARKLoTへ</h3>
              <p>
                応募規約の作成は、一から作るとなると手間がかかります。ツールを利用すれば、応募規約作成の工数を減らすことも可能です。
              </p>
              <p>
                PARKLoTでは、Twitterキャンペーンに関するサービスを提供しています。PARKLoTのシステムを使用すれば、応募規約も簡単に作成することが可能です。手間をかけずに、スピーディーに応募規約を作成したい方はPARKLoTまで、ぜひ一度ご連絡ください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/how-to-create-twitter-conversation-button-and-conversation-card-for-free"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【最新】Twitterカンバセーションボタンを無料で作る方法｜ハッシュタグ用カンバカードとは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/ten-things-to-check-for-Increasing-twitter-followers-through-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワー数が伸びない時こそ確認したい10選【プレゼント企画を成功させるには？】
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-instant-win-campaign-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
                  </a>
                </li>  
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default TwitterCampaignRulesTemplateWhatToIncludeToPreventTrouble
